<div class="ds-rows">
  <div class="ds-group --no-indent">
    <header class="ds-banner --plain --indent-small">
      <div>
        <h4>{{'traffic_charts.cumulative' | translate}}</h4>
      </div>
    </header>
    <div class="ds-controls">
      <div></div>
      <div>
        <button class="ds-button --icon-refresh-cw --hide-label" [disabled]="loadingCumulativeChart" (click)="updateCumulativeChart()">
          <span>{{ 'common.reload' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="ds-rows  --indent-small">
      <ds-notices [notices]="cumulativeChartNotices"></ds-notices>
      <!-- <app-alerts [alertsManager]="cumulativeChartAlertsManager"></app-alerts> -->
      <app-cumulative-traffic-chart
        [startDate]="cumulativeChartStartDate"
        [endDate]="cumulativeChartEndDate"
        [chartData]="cumulativeChartData"
      ></app-cumulative-traffic-chart>
    </div>
  </div>
  <div class="ds-group --no-indent">
    <header class="ds-banner --plain --indent-small">
      <div>
        <h4>{{'traffic_charts.data_usage' | translate}}</h4>
        <p>
          {{ 'traffic_charts.description' | translate }}
        </p>
      </div>
    </header>
    <div class="ds-controls">
      <div>
        <p class="ds-text --label">{{ 'traffic_charts.period' | translate }}</p>
        <select class="ds-select" [formControl]="usageChartPeriod" (change)="usagePeriodChangeHandler()">
          <option *ngFor="let period of periods" [value]="period">
            {{getPeriodLabel(period)}}
          </option>
        </select>
        <div class="ds-input">
          <input type="date" name="usageChartDate" [formControl]="startDate" [max]="maxDate" [min]="minDate" (change)="usageChargeDateChangeHandler()">
        </div>
        <div>UTC</div>
      </div>
      <div>
        <button class="ds-button --icon-refresh-cw --hide-label ds-span --end" [disabled]="loadingUsageChart" (click)="updateUsageChart()">
          <span>{{ 'common.reload' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="ds-rows --indent-medium">
      <app-traffic-usage-chart [chartData]="usageChartData" [timeUnit]="usageChartPeriod.value!"></app-traffic-usage-chart>
    </div>
  </div>
</div>
