import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CumulativeTrafficChartComponent } from './cumulative-traffic-chart/cumulative-traffic-chart.component';
import { TrafficUsageChartComponent } from './traffic-usage-chart/traffic-usage-chart.component';
import { NgChartsModule } from '@soracom/shared-ng/ng-charts';

@NgModule({
  declarations: [CumulativeTrafficChartComponent, TrafficUsageChartComponent],
  exports: [CumulativeTrafficChartComponent, TrafficUsageChartComponent],
  imports: [CommonModule, NgChartsModule],
})
export class TrafficChartsModule {}
