import moment from 'moment';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { CumulativeTrafficChartData } from './traffic';
import { TrafficUsageChartData } from './traffic';
import { TrafficStats } from './traffic';
import { Injectable, inject } from '@angular/core';
import { StatsApiService } from '@soracom/shared-ng/soracom-api-ng-client';
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
export type TimeUnit = 'month' | 'hour' | 'day';
export const trafficDataRetentionPeriodMonths = 18;
export const hourlyTrafficDataRetentionPeriodMonths = 1;

export type StatsPeriod = 'minutes' | 'day' | 'month';

const StatsPeriodMap: Record<TimeUnit, StatsPeriod> = {
  month: 'month',
  day: 'day',
  hour: 'minutes',
} as const;

type SpeedClass = string;

type TrafficType = 'Download' | 'Upload';

type TrafficDataSeries = {
  trafficType: TrafficType;
  speedClass: SpeedClass;
  data: number[];
};

type TrafficData = {
  times: number[];
  datasets: Array<TrafficDataSeries>;
};

@Injectable({
  providedIn: 'root',
})
export class TrafficDataService {
  private statsApi = inject(StatsApiService);

  constructor() {}

  getTrafficUsageChartData(
    simIdOrImsi: string,
    timeUnit: TimeUnit,
    fromEpochMs: number | undefined,
  ): Promise<TrafficUsageChartData> {
    const { start, end } = getChartTimeRange(timeUnit, fromEpochMs);
    return this.getTrafficStats(simIdOrImsi, timeUnit, start, end).then(
      convertToTrafficUsageChartData,
    );
  }

  getCumulativeTrafficChartDataForMonth(
    simIdOrImsi: string,
    yyyymm: string | null,
  ): Promise<CumulativeTrafficChartData> {
    const start = moment.utc(yyyymm, 'YYYYMM');
    const end = start.clone().endOf('month');
    return this.getTrafficStats(simIdOrImsi, 'day', start, end).then(
      convertToCumulativeTrafficChartData,
    );
  }

  getTrafficStats(
    simIdOrImsi: string,
    timeUnit: TimeUnit,
    from: number | moment.Moment,
    to: number | moment.Moment,
  ): Promise<TrafficData> {
    const fromM = moment(from);
    const toM = moment(to);
    const promise =
      simIdOrImsi.length >= 19
        ? this.statsApi.getAirStatsOfSim({
            simId: simIdOrImsi,
            _from: fromM.unix(),
            to: toM.unix(),
            period: StatsPeriodMap[timeUnit],
          })
        : this.statsApi.getAirStats({
            imsi: simIdOrImsi,
            _from: fromM.unix(),
            to: toM.unix(),
            period: StatsPeriodMap[timeUnit],
          });

    return promise.then((resp) => {
      return aggregateData(resp.data as TrafficStats[], timeUnit, fromM, toM);
    });
  }
}

function getChartTimeRange(
  timeUnit: TimeUnit,
  fromEpochMs: number | undefined,
): { start: moment.Moment; end: moment.Moment } {
  if (timeUnit === 'month') {
    const thisMonthUtc = moment().utc().startOf('month');
    let startMonthUtc = thisMonthUtc
      .clone()
      .subtract(trafficDataRetentionPeriodMonths, 'months');
    let endMonthUtc = thisMonthUtc.clone();
    if (fromEpochMs != null) {
      startMonthUtc = moment.utc(fromEpochMs).startOf('month');
      endMonthUtc = moment.min(
        thisMonthUtc,
        startMonthUtc.clone().add(23, 'months'),
      );
    }
    return { start: startMonthUtc, end: endMonthUtc };
  } else if (timeUnit === 'day') {
    const todayUtc = moment().utc().startOf('day');
    let startDayUtc = todayUtc.clone().subtract(44, 'days');
    let endDayUtc = todayUtc.clone();
    if (fromEpochMs != null) {
      startDayUtc = moment.utc(fromEpochMs).startOf('day');
      endDayUtc = moment.min(todayUtc, startDayUtc.clone().add(44, 'days'));
    }
    return { start: startDayUtc, end: endDayUtc };
  } else if (timeUnit === 'hour') {
    const thisHourLocal = moment().startOf('hour');
    let startHourLocal = thisHourLocal.clone().subtract(47, 'hours');
    let endHourLocal = thisHourLocal.clone();
    if (fromEpochMs != null) {
      startHourLocal = moment.utc(fromEpochMs).startOf('hour');
      endHourLocal = moment.min(
        thisHourLocal,
        startHourLocal.clone().add(47, 'hours'),
      );
    }
    return { start: startHourLocal, end: endHourLocal };
  }
  throw Error(`Invalid time unit: ${timeUnit}`);
}

function enumerateSpeedClasses(data: TrafficStats[]): SpeedClass[] {
  const speedClassSet = new Set<string>();
  data.forEach((ts: TrafficStats) => {
    Object.keys(ts.dataTrafficStatsMap).forEach((sc) => {
      speedClassSet.add(sc);
    });
  });
  return Array.from(speedClassSet.values());
}

/*
 * - Aggregate (sum up) values for each time bucket
 * - fill missing data points between from and to
 */
function aggregateData(
  data: TrafficStats[],
  timeUnit: TimeUnit,
  from: moment.Moment,
  to: moment.Moment,
): TrafficData {
  data.sort((a, b) => a.unixtime - b.unixtime); // just in case

  const speedClasses = enumerateSpeedClasses(data);

  // A template of data object for each time point. e.g point['s1.fast']['download'] = 450 (bytes)
  const aggregated: Record<SpeedClass, Record<TrafficType, number[]>> = {};
  speedClasses.forEach((label) => {
    aggregated[label] = {
      Download: [],
      Upload: [],
    };
  });

  const times: number[] = [];
  let i = 0;
  let t = moment(from).clone();
  while (t.isSameOrBefore(to)) {
    times.push(t.valueOf());

    t.add(1, timeUnit);

    const tmp = speedClasses.reduce(
      (o, sc) => {
        o[sc] = { Download: 0, Upload: 0 };
        return o;
      },
      {} as Record<string, { Download: number; Upload: number }>,
    );
    while (i < data.length && t.isAfter(data[i].unixtime * 1000)) {
      const d = data[i].dataTrafficStatsMap;
      speedClasses.forEach((sc) => {
        tmp[sc].Download += d[sc]?.downloadByteSizeTotal ?? 0;
        tmp[sc].Upload += d[sc]?.uploadByteSizeTotal ?? 0;
      });
      i++;
    }

    Object.keys(tmp).forEach((sc) => {
      aggregated[sc].Download.push(tmp[sc].Download);
      aggregated[sc].Upload.push(tmp[sc].Upload);
    });
  }

  const datasets: TrafficDataSeries[] = [];
  (['Download', 'Upload'] as TrafficType[]).forEach((type: TrafficType) => {
    speedClasses.forEach((sc) => {
      datasets.push({
        trafficType: type,
        speedClass: sc,
        data: aggregated[sc][type],
      });
    });
  });

  return {
    times,
    datasets,
  };
}

const tc: Record<TrafficType, string> = {
  Upload: '#d73e34',
  Download: '#236cff',
};

function getTrafficTypeColor(t: TrafficType) {
  return tc[t];
}

// TODO: use SDS color javascript
const ColorPalette: Record<string, Record<string, string> | string[]> = {
  // SDS red scale
  Upload: {
    's1.minimum': '#7c1008', // 800
    's1.slow': '#9d1a11', // 700
    's1.standard': '#ba261c', // 600
    's1.fast': '#d73e34', // 500
    's1.4xfast': '#ed5657', // 400
    'nec.xfast': '#f78991', // 300
  },
  // SDS blue scale
  Download: {
    's1.minimum': '#003090', // 800
    's1.slow': '#003fbd', // 700
    's1.standard': '#004de8', // 600
    's1.fast': '#236cff', // 500
    's1.4xfast': '#568eff', // 400
    'nec.xfast': '#8db3ff', // 300
  },
  // SDS red scale
  'Upload-spares': [
    '#ba261c', // 600
    '#d73e34', // 500
    '#ed5657', // 400
    '#f78991', // 300
  ],
  // SDS blue scale
  'Download-spares': [
    '#004de8', // 600
    '#236cff', // 500
    '#568eff', // 400
    '#8db3ff', // 300
  ],
};

function getDataSeriesColor(type: string, speedClass: string) {
  let color = (ColorPalette[type] as Record<string, string>)[speedClass];

  if (color === undefined || color === null) {
    const a = ColorPalette[type + '-spares'] as string[];
    const hash = hashCode(speedClass);
    const index = hash % a.length;
    color = a[index];
  }

  return color.toString();
}

function hashCode(str: string): number {
  let hash = 0;
  let chr;
  if (str.length === 0) return hash;
  for (let i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  if (hash < 0) {
    hash = -hash;
  }
  return hash;
}

function getDataSeriesLabel(t: TrafficType, sc: SpeedClass) {
  return `${t} - ${sc}`;
}

function convertToCumulativeTrafficChartData(
  stats: TrafficData,
): CumulativeTrafficChartData {
  const now = Date.now();
  return (['Download', 'Upload'] as TrafficType[]).map((t: TrafficType) => {
    let sum = 0;
    const data = stats.times
      // sum up same traffic type values for each point of time
      .map((x, i) => {
        const y = stats.datasets
          .filter((ds) => ds.trafficType === t)
          .reduce((sum, ds) => sum + ds.data[i], 0);
        return { x, y };
      })
      // trim future data points
      .filter(({ x }) => x <= now)
      // cumulative sum
      .map(({ x, y }) => ({ x, y: (sum += y) }));
    return {
      color: getTrafficTypeColor(t),
      label: t,
      data,
    };
  });
}

function convertToTrafficUsageChartData(
  stats: TrafficData,
): TrafficUsageChartData {
  // TODO: order of series
  const datasets: TrafficUsageChartData['datasets'] = stats.datasets.map(
    (ds) => ({
      label: getDataSeriesLabel(ds.trafficType, ds.speedClass),
      color: getDataSeriesColor(ds.trafficType, ds.speedClass),
      data: ds.data,
    }),
  );
  return {
    times: stats.times,
    datasets,
  };
}
