import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ChartDataset,
  ChartOptions,
  ScatterDataPoint,
  TooltipItem,
} from 'chart.js';
import dayjs from 'dayjs';
import { formatBytes } from '@soracom/shared-ng/ui-common';
import { CumulativeTrafficChartData } from '../traffic';

/** Internal data-point model */
interface DataPoint extends ScatterDataPoint {
  /** Actual epoch ms */
  time: number;

  /** time - (timezone's offset from UTC), for chart.js algorithm*/
  x: number;

  /** value in bytes */
  y: number;
}

// Utility to get timestamp that is subtracted offset from UTC
//   i.e if time = 1/1 10:00 UTC and user's tz offset is +09:00, this returns 1/1 01:00 UTC
// This is because Chart.js has some difficulties in handling UTC time,
//  regarding automatic grid/tick generation
// Namely, smart tick generation algorithm of chart.js works on assumption of local time
// it generates timestamp of 00:00 at localtime, even if we have data points with timestamp of 00:00 UTC
const getOffsetTimeForChart = (time: number) =>
  time - dayjs(time).utcOffset() * 60 * 1000;

@Component({
  selector: 'app-cumulative-traffic-chart',
  template: `
    <app-chart-container style="height: 400px;">
      <canvas
        baseChart
        type="line"
        [datasets]="datasets"
        [options]="options"
      ></canvas>
    </app-chart-container>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CumulativeTrafficChartComponent {
  datasets: ChartDataset<'line', DataPoint[]>[] = [];

  /**epoch ms */
  @Input() startDate?: number;

  /**epoch ms */
  @Input() endDate?: number;

  @Input() set chartData(chartData: CumulativeTrafficChartData | undefined) {
    this.datasets = (chartData || []).map((c) => ({
      label: c.label,
      backgroundColor: c.color,
      borderColor: c.color,
      pointBackgroundColor: c.color,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: c.color,
      data: c.data.map((v) => ({
        time: v.x,
        x: getOffsetTimeForChart(v.x),
        y: v.y,
      })),
    }));
  }

  constructor(private i18n: TranslateService) {}

  get options(): ChartOptions<'line'> {
    const min = this.startDate
      ? getOffsetTimeForChart(this.startDate)
      : undefined;
    const max = this.endDate ? getOffsetTimeForChart(this.endDate) : undefined;
    return {
      maintainAspectRatio: false,
      parsing: false,
      scales: {
        x: {
          type: 'time',
          min,
          max,
          time: {
            unit: 'day',
            stepSize: 5,
            displayFormats: {
              day: 'YYYY-MM-DD',
            },
            tooltipFormat: 'L',
          },
          bounds: 'ticks',
          title: {
            text: this.i18n.instant('traffic_charts.cumulative_xlabel'),
            display: true,
          },
        },
        y: {
          min: 0,
          ticks: {
            callback: (f: string | number) => formatBytes(Number(f)),
          },
          title: {
            text: 'Bytes',
            display: true,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (items: TooltipItem<'line'>[]) => {
              const { time } = items[0]?.raw as DataPoint;
              const utc = dayjs.utc(time).format('YYYY-MM-DD');
              const local = dayjs(time).format('YYYY-MM-DD HH:mm');
              return (
                this.i18n.instant('traffic_charts.cumulative_as_of_utc', {
                  utc,
                }) +
                ' ' +
                this.i18n.instant('traffic_charts.cumulative_as_of_local', {
                  local,
                })
              );
            },
            label: (item: TooltipItem<'line'>) => {
              const label = item.dataset.label;
              const { y } = item.raw as { y: number };
              return `${label}: ${formatBytes(
                y,
              )} (${y.toLocaleString()} bytes)`;
            },
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
    };
  }
}
