import { ChartData, ChartOptions, Tick, TooltipItem } from 'chart.js';
import dayjs from 'dayjs';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { formatBytes } from '@soracom/shared-ng/ui-common';
import { TrafficUsageChartData } from '../traffic';
import { TimeUnit } from '../traffic_data.service';

type config = {
  xAxisLabelId: string;
  timeFormat: string;
  timeStep: dayjs.UnitType;
};

const getTimestampFormatter = (unit: TimeUnit) => {
  const m = unit === 'hour' ? dayjs : dayjs.utc;
  const timeFormat = timeUnitConfig[unit].timeFormat;
  return (t: string | number) => m(t).format(timeFormat);
};

const timeUnitConfig: Record<TimeUnit, config> = {
  month: {
    xAxisLabelId: 'traffic_charts.month_xlabel',
    timeFormat: 'YYYY-MM',
    timeStep: 'month',
  },
  day: {
    xAxisLabelId: 'traffic_charts.day_xlabel',
    timeFormat: 'YYYY-MM-DD',
    timeStep: 'day',
  },
  hour: {
    xAxisLabelId: 'traffic_charts.hour_xlabel',
    timeFormat: 'YYYY-MM-DD H:mm',
    timeStep: 'hour',
  },
};

@Component({
  selector: 'app-traffic-usage-chart',
  template: `
    <app-chart-container [style.height]="300">
      <canvas baseChart type="bar" [data]="data" [options]="options"></canvas>
    </app-chart-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficUsageChartComponent {
  @Input() timeUnit: TimeUnit = 'month';

  @Input() chartData?: TrafficUsageChartData;

  constructor(private i18n: TranslateService) {}

  get data(): ChartData<'bar'> {
    if (!this.chartData) {
      return { datasets: [] };
    }

    return {
      labels: this.chartData.times,
      datasets: this.chartData.datasets.map((ds) => ({
        label: ds.label,
        backgroundColor: ds.color,
        borderColor: ds.color,
        hoverBackgroundColor: ds.color,
        data: ds.data,
      })),
    };
  }

  get timeFormat(): string {
    return timeUnitConfig[this.timeUnit].timeFormat;
  }

  get xAxisLabel(): string {
    return this.i18n.instant(timeUnitConfig[this.timeUnit].xAxisLabelId);
  }

  get options(): ChartOptions<'bar'> {
    const format = getTimestampFormatter(this.timeUnit);
    return {
      scales: {
        x: {
          stacked: true,
          title: {
            text: this.xAxisLabel,
            display: true,
          },
          ticks: {
            callback: function (v, i, ticks: Tick[]) {
              // Decimate tick labels to be nice-looking.
              // A bar chart uses categorical scales for the x-axis but in this case x-axis values are continuous time values. So we can omit some of them without lacking information.
              const step = Math.ceil(ticks.length / 10);
              if (i % step === 0 || i === ticks.length - 1) {
                // Retrieve raw time value and returns formatted string
                // Not that `this` here is the `Chart` instance
                // See https://www.chartjs.org/docs/3.5.1/axes/labelling.html#creating-custom-tick-formats
                const time: string = this.getLabelForValue(v as number);
                return format(time);
              }
              return '';
            },
            autoSkip: false,
          },
        },
        y: {
          stacked: true,
          min: 0,
          ticks: {
            callback: (f: string | number) => formatBytes(Number(f)),
          },
          title: {
            text: 'Bytes',
            display: true,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (items: TooltipItem<'bar'>[]) => {
              // label is epoch ms but is transformed into `string`
              const m = dayjs(Number.parseInt(items[0].label));
              const hourFormat = timeUnitConfig['hour'].timeFormat;
              const localStart = m.format(hourFormat);
              const localEnd = m
                .clone()
                .add(1, this.timeUnit)
                .format(hourFormat);
              const utc = m.utc().format(this.timeFormat);
              return (
                this.tr(`traffic_charts.${this.timeUnit}_utc`, { utc }) +
                ' ' +
                this.tr(`traffic_charts.${this.timeUnit}_local`, {
                  localStart,
                  localEnd,
                })
              );
            },
            label: (item: TooltipItem<'bar'>) => {
              const bytes = item.raw as number;
              if (bytes) {
                const { label } = item.dataset;
                return `${label}: ${formatBytes(
                  bytes,
                )} (${bytes.toLocaleString()} bytes)`;
              }
              return '';
            },
          },
        },
      },
    };
  }

  tr(key: string, param?: Object) {
    return this.i18n.instant(key, param);
  }
}
